<!--
 * @Descripttion:账号权限
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 14:27:48
-->
<template>
	<div class="bg vh">
		<van-nav-bar title="操作员管理" left-arrow @click-left="$router.push('/mine')" />

		<div class="pad4">
			<div class="whiteBg p5 center" v-if="!workweixin">
				<h3 class="fl45 fc center">扫码添加账户操作员</h3>
				<img :src="imgUrl">
				<p>注：添加新操作员后，其他操作员会失效。可以在下面的列表中指定生效操作员。</p>
			</div>
			<ul class="userUl whiteBg">
				<li class="whiteBg flex-between" v-for="(item,index) in list" :key="index">
					<p class="fl45">{{ item.name }}</p>
					<input type="checkbox" name="test" :value="item.id" v-model="checked" @click="checkedInput(item.id)">
				</li>
			</ul>
		</div>
		<div class="fixed-bottom" v-if="list.length > 0">
			<button class="w-100 inline" @click="doSave()">保存</button>
		</div>

	</div>
</template>

<script>
	import global from "@/common/global"
	export default {
		data() {
			return {
				list: [{
						id: '001',
						operatorFlag: 0,
						name: '昵称一号',
						mobilephone: '18888888881'
					},
					{
						id: '002',
						operatorFlag: 0,
						name: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea harum saepe aut corrupti dolor veritatis ab, deleniti voluptate a ad minus. Quidem nisi quisquam soluta cum ipsa. Sequi, velit enim.',
						mobilephone: '18888888888'
					},
					{
						id: '003',
						operatorFlag: 0,
						name: '昵称2号',
						mobilephone: '18888888882'
					},
					{
						id: '004',
						operatorFlag: 0,
						name: '昵称3号',
						mobilephone: '18888888883'
					},
					{
						id: '005',
						operatorFlag: 0,
						name: '昵称4号',
						mobilephone: '18888888884'
					},
				],
				checked: [],
				org: {},
				sceneId: '',
				currentAddUser: {
					id: '',
					name: '',
					openid: '',
					mobilephone: ''
				},
				imgUrl: '',
				intervalId: '',
				access_token: '',
				websocket: null,
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		beforeMount() {
			let edCurrentOrgExternal = this.$ls.get("edCurrentOrgExternal")
			if (edCurrentOrgExternal) {
				this.sceneId = edCurrentOrgExternal.seq
				this.org = this.$ls.get("edCurrentOrg");
				this.selectOperators()
				this.getAccessToken();
				this.initSocket();

				let that = this
				//5s
				this.intervalId = setInterval(function() {
					if (that.active) {
						if (that.currentAddUser.name == "") {
							that.getCustomer()
						}
					}
				}, 5000)
			} else {
				this.$toast.fail("中介机构信息不存在！")
				return
			}
		},

		beforeDestroy() {
			clearInterval(this.intervalId);
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
		},
		methods: {

			/**
			 * @Description: 查询操作员数据
			 * @Author: Niklaus
			 * @Date: 2021-06-10 16:14:45
			 */
			selectOperators() {
				let params = new URLSearchParams()
				params.append("orgId", this.$ls.get("edCurrentOrg").id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				//企微
				if(this.workweixin){
					params.append("suiteId",global.suiteId)
					this.$ajax({
						methods: 'get',
						url: '/gateway/ed/wx/wxEdOrgUserMap/workweixinList/',
						headers: {
							'X-Access-Token': this.$ls.get("token")
						},
						params: params,
					}).then(res => {
						if (res.data.success) {
							this.list = res.data.result || []
							for (let i = 0; i < this.list.length; i++) {
								if (this.list[i].operatorFlag == '1') {
									this.checked.push(this.list[i].id)
								}
							}
						} else {
							this.$toast.fail("失败")
							console.log(res.data.message)
						}
					})
				}
				//微信
				else{
					this.$ajax({
						methods: 'get',
						url: '/gateway/ed/wx/wxEdOrgUserMap/list/',
						headers: {
							'X-Access-Token': this.$ls.get("token")
						},
						params: params,
					}).then(res => {
						if (res.data.success) {
							this.list = res.data.result || []
							for (let i = 0; i < this.list.length; i++) {
								if (this.list[i].operatorFlag == '1') {
									this.checked.push(this.list[i].id)
								}
							}
						} else {
							this.$toast.fail("失败")
							console.log(res.data.message)
						}
					});
				}				
			},

			/**
			 * @Description: 清除复选框数据，只保留最新选中项
			 * @Author: Niklaus
			 * @Date: 2021-06-10 18:13:35
			 */
			checkedInput(id) {
				this.checked = []
				this.checked.push(id)
			},

			/**
			 * @Description: 保存修改（拷贝的原方法）
			 * @Author: Niklaus
			 * @Date: 2021-06-11 10:56:03
			 */
			doSave() {
				if (this.checked.length == 0) {
					for (let i = 0; i < this.list.length; i++) {
						this.list[i].operatorFlag = '0';
					}
				} else {
					for (let i = 0; i < this.list.length; i++) {
						if (this.list[i].id == this.checked[0])
							this.list[i].operatorFlag = '1'
						else
							this.list[i].operatorFlag = '0'
					}
				}
				//企业微信
				if(this.workweixin){
					this.$ajax({
						method: "post",
						url: "/gateway/ed/wx/wxEdOrgUserMap/workweixinBatchEdit",
						headers: {
							"X-Access-Token": this.$ls.get("token"),
						},
						params: {
							openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
						},
						data: this.list
					}).then((res) => {
						if (res.data.success) {
							this.$toast.success("修改成功")
						} else {
							this.$toast.fail(res.data.message)
						}
					})
				}
				//微信
				else{
					this.$ajax({
						method: "post",
						url: "/gateway/ed/wx/wxEdOrgUserMap/batchEdit",
						headers: {
							"X-Access-Token": this.$ls.get("token"),
						},
						params: {
							openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
						},
						data: this.list
					}).then((res) => {
						if (res.data.success) {
							this.$toast.success("修改成功")
						} else {
							this.$toast.fail(res.data.message)
						}
					});
				}				
			},


			initSocket() {
				this.initWebSocket();
			},

			initWebSocket: function() {
				let wssUrl = global.wsurl + this.org.id;
				this.websocket = new WebSocket(wssUrl);
				this.websocket.onopen = this.wsOnOpen;
				this.websocket.onclose = this.wsOnClose;
				this.websocket.onerror = this.wsOnError;
				this.websocket.onmessage = this.wsOnMessage;
			},

			wsOnOpen: function() {
				console.log('链接成功')
			},
			/**
			 * 关闭
			 */
			wsOnClose: function(e) {
				console.log("连接关闭")
			},
			/**
			 * 错误
			 */
			wsOnError: function(e) {
				console.log('链接错误')
				console.log(JSON.stringify(e))
			},
			/**
			 * 接收消息
			 */
			wsOnMessage: function(txt) {
				//接收到customerid = 当前扫码关注
				var obj = JSON.parse(txt.data);
				//0 成功
				if (obj.code == '0') {
					this.currentAddUser.id = obj.userId;
					this.currentAddUser.openid = obj.openId;
					this.$ls.set('currentAddUser', this.currentAddUser);
					this.$toast.success("添加操作员成功")
					this.selectOperators()
				} else {
					//1 失败
					this.$toast.fail("添加操作员失败：" + obj.message)
				}
			},

			getCustomer() {
				if (this.currentAddUser.id == null || this.currentAddUser.id == "")
					return;
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/integral/wechat/getCustomerExternal",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid")),
						customerId: this.currentAddUser.id,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {

						if (res.data.nickname) {
							this.selectOperators()
							//关闭当前页面定时任务
							clearInterval(this.intervalId)
						}
					}
				})
			},

			//获取tiket
			getTicket() {
				let url = "/wxauth/cgi-bin/qrcode/create?access_token=" + this.access_token
				let data = {
					expire_seconds: 604800,
					action_name: "QR_SCENE",
					action_info: {
						scene: {
							scene_id: this.sceneId
						}
					},
				}
				//
				this.$ajax.post(url, data).then((res) => {
					if (res.status == "200") {
						this.getQRCode(res.data.ticket)
					}
				})
			},
			//获取二维码图片
			getQRCode(ticket) {
				this.imgUrl = "/wxmp/cgi-bin/showqrcode?ticket=" + ticket
			},

			getAccessToken() {
				let __this = this
				//从本地缓存获取token
				let localToken = this.$ls.get("access-token-cache");
				//有效默认为true
				let expired = true;
				//
				if (localToken) {
					let currentTime = new Date().getTime();
					if (currentTime >= localToken.expiredTime) {
						expired = false;
					}
				} else {
					expired = false;
				}
				//
				if (!expired) {
					//请求access-token
					let url = "/wxauth/cgi-bin/token?grant_type=client_credential&appid=" + global.appid + "&secret=" + global.secret
					this.$ajax
						.get(url)
						.then((res) => {
							if (res.status == "200") {
								let tokenTmp = {}
								tokenTmp.token = res.data.access_token
								tokenTmp.expiredTime = (new Date().getTime()) + res.data.expires_in
								__this.$ls.set("access-token-cache", tokenTmp)
								//
								__this.access_token = res.data.access_token
								//获取tiket
								__this.getTicket()
							}
						});
				} else {
					this.access_token = localToken.token
					//获取tiket
					this.getTicket()
				}
			},
		}
	}
</script>

<style scoped>
	.p5 {
		padding: .53rem .83rem;
	}

	.p5>img {
		width: 5.33rem;
		height: 5.33rem;
		margin: .43rem auto;
	}

	.p5>p {
		color: #A6A6A6;
		font-size: .37rem;
		line-height: .48rem;
		font-weight: 400;
	}

	.userUl {
		margin-bottom: 1.5rem;
		margin-top: .43rem;
	}

	.userUl>li {
		padding: .43rem;
	}

	.userUl>li>p {
		width: calc(100% - 2rem);
		flex-shrink: 0;
		color: #1E1E1E;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	input[name=test] {
		width: .43rem;
		height: .43rem;
	}

	.fixed-bottom {
		padding: .27rem .43rem;
		background-color: #fff;
	}

	.fixed-bottom button {
		background-color: #00CCB2;
		color: white;
		padding: .21rem;
		border-radius: .6rem;
		font-size: .45rem;
		font-weight: bold;
	}
</style>
